import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiArrowRight } from 'react-icons/fi';
import { formatCurrency } from '../../utils/currencyUtils';
//import CustomPagination from './CustomPagination/CustomPagination';
import SkeletonTable from '../common/SkeletonTable';
import { exportToCSV } from '../../utils/exportCSV';

const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'rgb(248, 250, 252)',
        fontWeight: "bold",
      },
    },
};

const TransactionTable = ({ transactions, loading }) => {
  const [filterText, setFilterText] = useState('');
  /*const [data, setData] = useState([]);
  
  useEffect(() => {
    // Load data from JSON file
    setData(sampleData);
  }, []);*/

  const columns = [
    {
      name: "Who Paid?",
      cell: row => {
        if (row.type === "discount_settlement") {
          return row.metadata?.parent
            ? `${row.metadata.parent.firstname || "N/A"} ${row.metadata.parent.lastname || ""}`.trim()
            : "Discount Settlement";
        } else if (row.type === "withdrawal") {
          return "Withdrawal";
        }
        return row.metadata?.parent_name && row.metadata.parent_name !== "undefined undefined"
          ? row.metadata.parent_name
          : "N/A";
      },
    },    
    {
      name: "Student Name",
      cell: row => {
        if (row.type === "discount_settlement") {
          return row.child
            ? `${row.child.firstname || "N/A"} ${row.child.lastname || ""}`.trim()
            : "Discount Settlement";
        } else if (row.type === "withdrawal") {
          return "Withdrawal";
        }
        return row.child ? `${row.child.firstname} ${row.child.lastname}` : "N/A";
      },
    },
    {
      name: "Student Class",
      cell: row => {
        if (row.type === "discount_settlement") {
          return "Discount Settlement";
        } else if (row.type === "withdrawal") {
          return "Withdrawal";
        }
        return row.metadata?.class?.class_name || "N/A";
      },
    },
    {
      name: "Amount Paid",
      cell: row => (
        <span style={{ color: row.type === "discount_settlement" ? "green" : row.type === "withdrawal" ? "red" : "black" }}>
          {formatCurrency(row.amount)}
        </span>
      ),
      exportCell: row => formatCurrency(row.amount)
    },
    {
      name: "Total Fees",
      cell: row => formatCurrency(row.total_amount),
    },
    {
      name: "Transaction Type",
      cell: row => (
        <button
          style={{
            background: row.type === "withdrawal"
              ? "rgb(227, 79, 79)" // Red
              : row.type === "discount_settlement"
              ? "rgb(139, 231, 139)" // Green
              : row.type === "school_fee"
              ? "rgba(56, 48, 163, 0.71)" // Blue
              : "rgb(200, 200, 200)", // Default gray
            fontSize: "11px"
          }}
          className="text-white py-1 px-2 rounded-2xl"
        >
          {row.type === "withdrawal" ? "Withdrawal" : row.type === "discount_settlement" ? "Settlement" : "School Fees"}
        </button>
      ),
      exportCell: row =>
        row.type === "withdrawal"
          ? "Withdrawal"
          : row.type === "discount_settlement"
          ? "Settlement"
          : "School Fees",
    },    
    {
      name: "Total Paid",
      cell: row => formatCurrency(row.amount_after),
    },
    {
      name: "Discount Amount",
      cell: row => formatCurrency(row?.marketing_discount?.amount),
    },
    {
      name: "Balance",
      cell: row => formatCurrency(Number(row.total_amount) - Number(row.amount_after)),
    },
    {
      name: "Paid At",
      cell: row => new Date(row.updatedAt).toLocaleString("en-NG", { hour12: true }),
      sortable: true,
    },
    {
      cell: row => (
        <Link to={{ pathname: `/school/receipt/${row._id}`, state: { school: row.school } }}>
          <button style={{ color: "#1e4db7", backgroundColor: "#91a6f5" }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl">
            <FiArrowRight />
          </button>
        </Link>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const filteredData = transactions?.filter((item) =>
    (item.reference?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item._id?.toLowerCase().includes(filterText.toLowerCase()) || '')
  );
  
    return (
      <div className="rounded-2xl overflow-x-auto w-full bg-white p-6" style={{ maxWidth: "1100px" }}>
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            className="px-4 py-2 rounded-lg bg-gray-100 border-gray-100 text-gray-900"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button onClick={() => exportToCSV(filteredData, columns, `All-transactions.csv`)} className="inline-flex justify-center py-1 px-8 shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 float-right">
            Export to CSV
          </button>
        </div>
        <div className="overflow-x-auto w-full">
          <DataTable
            columns={columns}
            data={filteredData}
            getRowId={(row) => row._id}
            pagination
            //paginationComponent={CustomPagination}
            paginationRowsPerPageOptions={[10, 20, 30, 50]}
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>
      </div>
    );
};

export default TransactionTable;