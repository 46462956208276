import * as yup from 'yup'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
const phoneRules = /^(0)\d{10}$/;
const supportedFileTypes = ['application/pdf', 'text/csv'];
const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

export const signupSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    lastname: yup.string().required("Last name is required"),
    school_name: yup.string().required("Required"),
    mobile_number: yup.string().min(10, "Mobile number must be at least 10 characters")
        .matches(phoneRules, { message: "Provide a valid Mobile number" })
        .required("Required"),
    email: yup.string().email("Please enter a valid email").required("Required"),
    password: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Please create a stronger password, minimum 8 characters" })
        .required("Required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Required"),
});

export const signinSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required("Required"),
    password: yup
        .string()
        .min(6)
        .matches(passwordRules, { message: "Please create a stronger password, minimum 8 characters" })
        .required("Required"),
});

export const informationSchema = yup.object().shape({
    school_name: yup.string().required("Legal Business name is required"),
    //tradingName: yup.string().required("Trading Name is required"),
    phone_number: yup.string().min(10, "Mobile number must be at least 10 characters")
    .matches(phoneRules, { message: "Provide a valid Mobile number" })
    .required("Required"),
    bvn: yup.string()
        .matches(/^[0-9]{11}$/, "BVN must be exactly 11 numeric digits")
        .required("BVN is required"),
    date_of_registration: yup.date()
        .typeError("Invalid date format")
        .required("Date is required")
        .max(new Date(), "Date cannot be in the future"),
    registration_type: yup.string().required("Registration Type is required"),
    industry: yup.string().required("Industry is required"),
    description: yup.string().required("Description is required"),
});

export const contactSchema = yup.object().shape({
    /*mobile_number: yup.string().min(10, "Mobile number must be at least 10 characters")
        .matches(phoneRules, { message: "Provide a valid Mobile number" })
        .required("Required"),*/
    //email: yup.string().email("Please enter a valid email").required("Required"),
    facebook: yup.string(),
    twitter: yup.string(),
    instagram: yup.string(),
});

export const addressSchema = yup.object().shape({
    address_line: yup.string().required("Address is required"),
    city: yup.string().required("city is required"),
    state: yup.string().required("state is required"),
    zip_code: yup.string()
        .matches(/^[0-9]{5,}$/, "Zip Code must be at least 5 numeric digits")
        .required("Zip Code is required"),
    address_line2: yup.string().required("Address is required"),
    city2: yup.string().required("city is required"),
    state2: yup.string().required("state is required"),
    zip_code2: yup.string()
        .matches(/^[0-9]{5,}$/, "Zip Code must be at least 5 numeric digits")
        .required("Zip Code is required"),
});

export const officialsSchema = yup.object().shape({
    role: yup.string().required("role is required"),
    first_name: yup.string().required("first name is required"),
    last_name: yup.string().required("last name is required"),
    middle_name: yup.string(),
    maiden_name: yup.string(),
    date_of_birth: yup.date()
        .typeError("Invalid date format")
        .required("Date is required")
        .max(new Date(), "Date cannot be in the future"),
    email: yup.string().email("Please enter a valid email").required("email is Required"),
    phone_number: yup.string().min(10, "Mobile number must be at least 10 characters")
        .matches(phoneRules, { message: "Provide a valid Mobile number" })
        .required("Required"),
    nationality: yup.string().required("nationality is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip_code: yup.string()
        .matches(/^[0-9]{5,}$/, "Zip Code must be at least 5 numeric digits")
        .required("Zip Code is required"),
    address_line: yup.string().required("Address is required"),
    bvn: yup.string()
    .matches(/^[0-9]{11}$/, "BVN must be exactly 11 numeric digits")
    .required("BVN is required"),
    title: yup.string().required("title is required"),
    percentage: yup.string().required("percentage is required"),
});

export const bankSchema = yup.object().shape({
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip_code: yup.string()
        .matches(/^[0-9]{5}$/, "Zip Code must be exactly 5 numeric digits")
        .required("Zip Code is required"),
    address_line: yup.string().required("Address is required"),

    account_name: yup.string().required("Country is required"),
    account_number: yup.string()
    .matches(/^[0-9]{10}$/, "Account number must be exactly 10 numeric digits")
    .required("Zip Code is required"),
    bank_code: yup.string()
    .matches(/^[0-9]{3,}$/, "Bank code must be at least 3 numeric digits")
    .required("Bank code is required"),
});

export const resetSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required("Required"),
});

export const recoverSchema = yup.object().shape({
    code: yup.string().min(6, "Code must be at least 6 characters").required("Required"),
    password: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Please create a stronger password, minimum 8 characters" })
        .required("Required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Required"),
});

export const fileUploadSchema = yup.object().shape({
    file: yup.mixed()
        .required('File is required')
        .test(
            'fileType',
            'Unsupported file type',
            value => value && supportedFileTypes.includes(value.type)
    )
        .test(
            'fileSize',
            'File size is too large',
            value => value && value.size <= maxFileSize
        ),
});

export const acceptInviteSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    lastname: yup.string().required("Last name is required"),
    //token: yup.string().required("Required"),
    password: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Please create a stronger password, minimum 8 characters" })
        .required("Required"),
});

// export const ResultMetricsSchema = yup.object().shape({
//     total_weight: yup.number().required(),
//     ca1: yup.number().test('equals-sum', "Sum of CA's cannot be greater than Total Weight. Adjust the values!", function(value){
//         const {ca2, ca3, total_weight} = this.parent
//         return value + ca2 + ca3 <= total_weight
//     }).required(),
//     ca2: yup.number().test('equals-sum', "Sum of CA's cannot be greater than Total Weight. Adjust the values!", function(value){
//         const {ca1, ca3, total_weight} = this.parent
//         return value + ca1 + ca3 <= total_weight
//     }).required(),
//     ca3: yup.number().test('equals-sum', "Sum of CA's cannot be greater than Total Weight. Adjust the values!", function(value){
//         const {ca2, ca1, total_weight} = this.parent
//         return value + ca2 + ca1 <= total_weight
//     }).notRequired(),
// }).test()

export const slaSchema = yup.object({
  flexpay_fee: yup
    .number()
    .required("Flexpay fee is required"),
    // Optional: .min(0, "Fee must be at least 0").max(1, "Fee cannot exceed 1"),
  paynow_fee: yup
    .number()
    .required("Paynow fee is required"),
    // Optional: .min(0, "Fee must be at least 0").max(1, "Fee cannot exceed 1"),
  flexpay_markup: yup
    .number()
    .required("Flexpay markup is required"),
    // Optional: .min(0, "Markup must be at least 0").max(1, "Markup cannot exceed 1"),
  sms_fee: yup
    .number()
    .required("SMS fee is required")
    // Optional: .min(0, "Fee must be at least 0").max(1, "Fee cannot exceed 1"),
});