import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { slaSchema } from '../../utils/formSchema';
import { useFormik } from 'formik';
import { userRequest } from '../../utils/requestMethods';
import ButtonLoading from '../ButtonLoading';
//import { formatter } from '../../utils/requestMethods';

const ServiceLevel = ({ schoolData, sla, onSlaAdded }) => {
    //console.log(mls?.Document_pattern);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    console.log(sla);

    useEffect(() => {
        if (sla && formik) {
            formik.setValues({
                ...sla,
                //date_of_registration: formatDate(schoolData?.date_of_registration),
            });
        }
    }, [sla]);
    

    const formik = useFormik({
        initialValues: {
            flexpay_fee: sla?.flexpay_fee || 0,
            paynow_fee: sla?.paynow_fee || 0,
            flexpay_markup: sla?.flexpay_markup || 0,
            sms_fee: sla?.sms_fee || 0,
        },
        validationSchema: slaSchema,
        onSubmit: (values) => {
            registerHandler(values);
            console.log(values);
        },
    });

    //console.log(formik.values);

    const registerHandler = async (values) => {
        setLoading(true);
        try {
            const allValues = {
                ...values,
                //date_of_registration: formatDate(formik.values.date_of_registration),
            };
            console.log(allValues)
            await userRequest.post(`/schools/${schoolData._id}/settings`, {
                sla: {
                ...allValues,
                }
                /*flexpay_fee: allValues.flexpay_fee,
                paynow_fee: allValues.paynow_fee,
                flexpay_markup: allValues.flexpay_markup,
                sms_fee: allValues.sms_fee,*/
            });

            setSuccess("Business service level agreement added");
            setTimeout(() => {
                setSuccess("");
                onSlaAdded();
            }, 2000);
        } catch (error) {
            console.log(error);
            setError("Something went wrong, try again in a few minutes!");
            setTimeout(() => {
                setError("");
            }, 5000);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="bg-white border border-gray-200 rounded-3xl shadow">
            <div className="m-2 p-5 divide-y">
                <div className="py-2">
                    <h3 className="text-lg font-bold dark:text-gray-200">Service Level Setting</h3>
                    <p>
                        Based on your business type, you will be required to submit the documents below during the business activation process.
                    </p>
                    <hr />
                </div>
            </div>
            <div className="m-2 p-5 divide-y">
                {success &&
                    <div className="alert alert-success d-flex align-items-center" role="alert">
                        <i className="fal fa-check pr-10"></i>
                        <div>
                            {success}
                        </div>
                    </div>
                }
                {error && 
                    <div className="alert alert-warning d-flex align-items-center" role="alert">
                        <i className="fal fa-lock pr-10"></i>
                        <div>
                            {error}
                        </div>
                    </div>
                }
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-1 gap-6">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">Pay Now Transaction Fee</p>
                                <input type="number" id="paynow_fee" name="paynow_fee" placeholder="Legal Business Name" value={formik.values.paynow_fee} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"/>
                                {formik.errors.paynow_fee && formik.touched.paynow_fee &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.paynow_fee}</p>}
                            </div>
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">Flexpay Transaction Fee</p>
                                <input type="number" id="flexpay_fee" name="flexpay_fee" placeholder="Legal Business Name" value={formik.values.flexpay_fee} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"/>
                                {formik.errors.flexpay_fee && formik.touched.flexpay_fee &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.flexpay_fee}</p>}
                            </div>
                        </div>
                        <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">School Management Transaction Fee/Student</p>
                                <input type="number" id="sms_fee" name="sms_fee" placeholder="Legal Business Name" value={formik.values.sms_fee} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"/>
                                {formik.errors.sms_fee && formik.touched.sms_fee &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.sms_fee}</p>}
                            </div>
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">School Flexpay Mark-Up</p>
                                <input type="number" id="flexpay_markup" name="flexpay_markup" placeholder="Legal Business Name" value={formik.values.flexpay_markup} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"/>
                                {formik.errors.flexpay_markup && formik.touched.flexpay_markup &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.flexpay_markup}</p>}
                            </div>
                        </div>
                        <div className="px-4 py-3 text-right sm:px-6">
                            {loading ?
                                (
                                    <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-800" />
                                ) : (
                                    <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ServiceLevel
