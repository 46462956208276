import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { useHistory } from "react-router-dom";
import { Button } from '.';
import { userProfileData } from '../data/dummy';
//import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';
import { userRequest } from '../utils/requestMethods';

const UserProfile = ({ user }) => {
  //const { currentColor, bgHoverColor, size } = useStateContext();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const logoutHandler = async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.post("auth/logout");
      console.log(data);
      //Cookies.remove('accessToken');
      localStorage.removeItem("tokenWithUser");
      history.push("/signin");
    } catch (error) {
      console.log(error);
      /*setError("Wrong email address or password!");
      setTimeout(() => {
        setError("");
      }, 5000);*/
      setLoading(false)
    }
  };

  return (
    <div className="nav-item absolute right-1 top-16 bg-white shadow-lg dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {user.firstname} {user.lastname} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">  Administrator   </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {user.email} </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <Link to={item.link} key={index}>
            <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className=" text-xl rounded-lg p-3 hover:bg-light-gray"
              >
                {item.icon}
              </button>

              <div>
                <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
                <p className="text-gray-500 text-sm dark:text-gray-400"> {item.desc} </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="mt-5">
        <button type="button" onClick={logoutHandler} className="w-full text-indigo-700 hover:text-white border border-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
          {loading ? ("Logging out...") : ("Logout")}
        </button>
      </div>
    </div>

  );
};

export default UserProfile;
