import axios from "axios";

const BASE_URL = "https://productionapi.acceede.com/" //Production AWS
//const BASE_URL = "https://acceedeapi.herokuapp.com"
//const BASE_URL = "https://staging-s55s.onrender.com" //staging
//const BASE_URL = "https://stagingapi.acceede.com"; //staging-AWS

//Wave
const BASE_URL_WAVE = "https://acceede-marketplace.onrender.com";

let isRefreshing = false;
let failedRequestsQueue = [];

const tokenWithUser = JSON.parse(localStorage.getItem("tokenWithUser"));
const initialToken = tokenWithUser?.access_token || '';

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-access-token": initialToken,
  },
});

export const adminRequest = axios.create({
  baseURL: BASE_URL_WAVE,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-access-token": initialToken,
  },
});

const refreshAccessToken = async () => {
  try {
    const tokenData = JSON.parse(localStorage.getItem("tokenWithUser"));
    if (!tokenData?.refresh_token) {
      throw new Error("No refresh token available");
    }

    const response = await publicRequest.post('/auth/refresh-token', {
      refresh_token: tokenData.refresh_token
    });

    const newAccessToken = response.data.access_token;
    const updatedTokenData = { ...tokenData, access_token: newAccessToken };
    
    localStorage.setItem("tokenWithUser", JSON.stringify(updatedTokenData));
    
    userRequest.defaults.headers['x-access-token'] = newAccessToken;
    adminRequest.defaults.headers['x-access-token'] = newAccessToken;

    return newAccessToken;
  } catch (error) {
    localStorage.removeItem("tokenWithUser");
    window.location.href = '/login';
    throw error;
  }
};

const handleUnauthorizedError = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    response => response,
    async (error) => {
      const originalRequest = error.config;
      
      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve) => {
            failedRequestsQueue.push((newToken) => {
              originalRequest.headers['x-access-token'] = newToken;
              resolve(axiosInstance(originalRequest));
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newToken = await refreshAccessToken();
          originalRequest.headers['x-access-token'] = newToken;
          
          const response = await axiosInstance(originalRequest);
          failedRequestsQueue.forEach(cb => cb(newToken));
          return response;
        } catch (refreshError) {
          failedRequestsQueue.forEach(cb => cb());
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
          failedRequestsQueue = [];
        }
      }

      return Promise.reject(error);
    }
  );
};

// Apply interceptors to both authenticated instances
handleUnauthorizedError(userRequest);
handleUnauthorizedError(adminRequest);

// Create our Currency formatter.
export const formatter = new Intl.NumberFormat("en-NG", {
  style: "currency",
  currency: "NGN",
  minimumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as ₦2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as ₦2,501)
});

//(2500).toLocaleString("en-NG", {style: "currency", currency: "NGN", minimumFractionDigits: 2})
